<template>
  <b-modal ref="dialog" title="Create Account" size="lg">
    <b-overlay :show="busy">
      <b-container fluid>
        <b-row class="mb-2">
          <b-col cols="2" class="col-form-label font-weight-bold">Name</b-col>
          <b-col><b-input v-model="newAccountData.name" /></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="2" class="col-form-label font-weight-bold">Email Address</b-col>
          <b-col><b-input v-model="newAccountData.email_address" /></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="2" class="col-form-label font-weight-bold">Username</b-col>
          <b-col><b-input v-model="newAccountData.username" /></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="2" class="col-form-label font-weight-bold">Start Date</b-col>
          <b-col><b-datepicker v-model="newAccountData.valid_from" /></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col offset="2"><b-checkbox v-model="newAccountData.notify">Send a welcome email with activation instructions.</b-checkbox></b-col>
        </b-row>
      </b-container>
    </b-overlay>
    <template slot="modal-footer">
      <b-btn variant="primary" @click="create" :disabled="!canCreate || busy">
        <template v-if="busy"><icon-text icon="spinner" spin>Creating...</icon-text></template>
        <template v-else>Create Account</template>
      </b-btn>
      <b-btn variant="secondary" @click="$refs.dialog.hide()" :disabled="busy">Cancel</b-btn>
    </template>
  </b-modal>
</template>

<script>
import IconText from '../../../components/IconText'

export default {
  components: { IconText },
  data: () => ({
    newAccountData: {
      name: '',
      email_address: '',
      username: '',
      valid_from: null,
      notify: true
    },
    busy: false
  }),
  computed: {
    canCreate () {
      return this.newAccountData.name !== '' && this.newAccountData.email_address !== '' && this.newAccountData.username !== ''
    }
  },
  watch: {
    'newAccountData.name': function (v) {
      const normalised = v.trim().toLowerCase()
      const parts = normalised.split(' ')

      let u = ''
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i]
        if (i < (parts.length - 1)) {
          u += part.slice(0, 1)
        } else {
          u += part
        }
      }

      this.newAccountData.username = u
    }
  },
  methods: {
    show () {
      this.newAccountData = Object.assign({}, this.newAccountData, {
        name: '',
        email_address: '',
        username: '',
        valid_from: new Date(),
        notify: true
      })

      this.$refs.dialog.show()
    },
    async create () {
      this.busy = true
      try {
        await API.accounts.create(this.newAccountData)
        this.$emit('reload')
        this.$refs.dialog.hide()
      } catch (error) {
        alert(error)
      } finally {
        this.busy = false
      }
    }
  }
}
</script>
