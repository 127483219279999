<template>
  <b-card class="shadow-sm" header="Account List">
    <div class="mb-3">
      <b-btn variant="success" @click="createAccount" class="mr-2" v-if="isAdmin"><icon-text icon="plus">Create Account</icon-text></b-btn>
      <b-btn variant="primary" :to="{ name: 'sync-accounts' }" class="mr-2"><icon-text icon="clone">Synchronise Accounts</icon-text></b-btn>
      <b-btn variant="secondary" @click="reload"><icon-text icon="sync">Reload</icon-text></b-btn>
    </div>

    <b-overlay :show="loading">
      <table class="table table-sm">
        <thead>
          <tr>
            <th width="70px">Enabled</th>
            <th>Name</th>
            <th width="250px">Email</th>
            <th width="150px">Username</th>
            <th width="120px">Services</th>
            <th width="100px">Status</th>
            <th width="100px">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in sortedAccounts" :key="account.id">
            <td class="align-middle"><fi :icon="account.enabled ? 'check' : 'times'" /></td>
            <td class="align-middle">
              <template v-if="isAdmin">
                <router-link :to="{ name: 'edit-account', params: { accountId: account.id } }">{{account.name}}</router-link>
              </template>
              <template v-else>
                {{account.name}}
              </template>
            </td>
            <td class="align-middle">{{account.email_address}}</td>
            <td class="align-middle">{{account.username}}</td>
            <td class="align-middle">
              <b-badge v-for="(service,idx) in account.services" :key="`SVC_${idx}`" class="mr-2" :variant="service.enabled ? 'success' : 'danger'">
                {{service.name}}
              </b-badge>
            </td>
            <td class="align-middle" :class="{ 'text-danger': !account.enabled }">
              <icon-text :icon="account.status === 'active' ? 'check' : 'exclamation'">{{formatStatus(account.status)}}</icon-text>
            </td>
            <td class="align-middle">
              <b-dropdown size="sm" text="Action" variant="info" right>
                <template v-if="isAdmin">
                  <b-dropdown-item :to="{ name: 'edit-account', params: { accountId: account.id } }"><icon-text icon="edit">Edit</icon-text></b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item v-if="account.enabled" @click="disableAccount(account)"><icon-text icon="times">Disable</icon-text></b-dropdown-item>
                  <b-dropdown-item v-else @click="enableAccount(account)"><icon-text icon="check">Enable</icon-text></b-dropdown-item>
                </template>
                <b-dropdown-item @click="resetPassword(account)"><icon-text icon="unlock">Reset Password</icon-text></b-dropdown-item>
                <template v-if="isAdmin">
                  <b-dropdown-divider />
                  <b-dropdown-item variant="danger" @click="deleteAccount(account)"><icon-text icon="trash">Delete</icon-text></b-dropdown-item>
                </template>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </b-overlay>

    <create-account-dialog ref="createAccountDialog" @reload="reload" />
    <reset-password-dialog ref="resetPasswordDialog" />
  </b-card>
</template>

<script>
import IconText from '@/components/IconText'
import ResetPasswordDialog from '@/components/ResetPasswordDialog'
import CreateAccountDialog from './CreateAccountDialog'
import { mapGetters } from 'vuex'

export default {
  components: { IconText, ResetPasswordDialog, CreateAccountDialog },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters({
      accounts: 'accounts/list',
      isAdmin: 'auth/isAdmin'
    }),
    filteredAccounts () {
      return this.accounts
    },
    sortedAccounts () {
      return this.filteredAccounts.slice().sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    async reload () {
      this.loading = true

      try {
        await this.$store.dispatch('accounts/load')
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false
      }
    },
    createAccount () {
      this.$refs.createAccountDialog.show()
    },
    formatStatus (s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    async enableAccount (acct) {
      try {
        await API.accounts.update({ id: acct.id, enabled: true })
        this.reload()
      } catch (error) {
        alert(error)
      }
    },
    async disableAccount (acct) {
      try {
        await API.accounts.update({ id: acct.id, enabled: false })
        this.reload()
      } catch (error) {
        alert(error)
      }
    },
    async deleteAccount (acct) {
      if (!(await this.$store.dispatch('dialog/showConfirm', { title: 'Delete Account', message: 'Are you sure you want to delete this account?' }))) {
        return
      }

      try {
        await API.accounts.delete(acct)
        this.reload()
      } catch (error) {
        alert(error)
      }
    },
    resetPassword (acct) {
      this.$refs.resetPasswordDialog.show(acct)
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
